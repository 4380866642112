function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/*
  USER_PROPS_SAFELIST is to contain any string deemed safe for user props.
  The startsWidth array will contain the start of any accepted user-prop that 
  starts with these characters.
  The exactMatch will contain a list of exact prop names that are accepted.
*/
var USER_PROPS_SAFELIST = {
  startsWith: ["data-", "aria-"],
  exactMatch: []
};
/**
 * doesPropStartWith: Function that takes a prop's key and runs it against all
 * options in the USER_PROPS_SAFELIST and checks to see if it starts with any
 * of those options.
 * @param {string} key: prop key to be tested against whitelist
 * @returns {Boolean}: returns true if the key starts with an option or false if
 * otherwise
 */

var doesPropStartWith = function (key) {
  var startsWith = false;
  USER_PROPS_SAFELIST.startsWith.forEach(function (starterString) {
    var regex = new RegExp("\\b(".concat(starterString, ")(\\w|-)+"), "g");
    if (regex.test(key)) startsWith = true;
  });
  return startsWith;
};
/**
 * isExactMatch: checks to see if the given key matches any of the 'exactMatch'
 * items in the whitelist
 * @param {String} key: prop key to be tested against the whitelist-exact match
 * array.
 * @returns {Boolean}: return true if whitelist contains that key, otherwise
 * returns false.
 */


var isExactMatch = function (key) {
  return USER_PROPS_SAFELIST.exactMatch.includes(key);
};
/**
 * testIfSafeProp: tests prop's key against both startsWith and exactMatch values
 * @param {String} key: prop key to be tested against the whitelist
 * @returns {Boolean}: returns true if found in whitelist, otherwise returns false
 */


var testIfSafeProp = function (key) {
  if (doesPropStartWith(key) || isExactMatch(key)) return true;
  return false;
};
/**
 * getSafeUserProps - function that takes in a props object and removes any
 * key-value entries that do not match filter strings in the USER_PROPS_SAFELIST
 * object.
 *
 * @param {Object} props: props to be filtered against USER_PROPS_SAFELIST
 * @returns {Object}: object containing remaining acceptable props
 */


export var getSafeUserProps = function (props) {
  var propsToFilter = _objectSpread({}, props);

  return Object.fromEntries(Object.entries(propsToFilter).filter(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        key = _ref2[0];

    return testIfSafeProp(key);
  }));
};